import React, { useState } from "react";
import Modal from "../modal/Modal";
import useAdminStore from "../../stores/useAdminStore";

function PictureCard({ polaroid }) {
  const [deleteModal, setDeleteModal] = useState(false);
  const [inn, setInn] = useState("");
  const { deletePolaroid, getPolaroids } = useAdminStore();

  const setDeleteModalVisible = (id) => {
    setDeleteModal(!deleteModal);
    setInn(id);
  };

  const setDeleteModalUnvisible = () => {
    setDeleteModal(!deleteModal);
  };

  return (
    <>
      <Modal isOpen={deleteModal} onClose={setDeleteModalUnvisible}>
        <div className="flex flex-col items-center">
          <p className="mb-4">Вы уверены, что хотите удалить картинку?</p>
          <div className="flex space-x-4">
            <button
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              onClick={async () => {
                await deletePolaroid(inn);
                await getPolaroids();
                setDeleteModalUnvisible();
              }}
            >
              Да
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={setDeleteModalUnvisible}
            >
              Нет
            </button>
          </div>
        </div>
      </Modal>

      <div className="relative mt-20 flex w-full justify-around">
        <div className="w-96 h-80 bg-white flex flex-col shadow-md">
          <div className="w-96 h-64">
            <img
              src={polaroid.image}
              alt=""
              className="w-96 h-64 p-5 rounded-sm object-cover"
            />
          </div>
          <div className="flex justify-end">
            <button
              className="self-end w-fit rounded-md p-2 mt-2 hover:bg-gray-200"
              onClick={() => setDeleteModalVisible(polaroid.id)}
            >
              <b>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M3 6h18" />
                  <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                  <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                  <line x1="10" x2="10" y1="11" y2="17" />
                  <line x1="14" x2="14" y1="11" y2="17" />
                </svg>
              </b>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PictureCard;
