export const languages = [
  {
    title: "ENG",
    key: "ENG",
  },
  {
    title: "РУС",
    key: "RUS",
  },
];
