export const navLinks = [
  {
    title: "Home",
    href: "/",
    id: 1,
  },
  {
    title: "Kyrgyzstan",
    href: "/kyrgyzstan",
    id: 2,
  },
  {
    title: "CentralAsia",
    href: "central",
    id: 3,
  },
  {
    title: "About",
    href: "/about",
    id: 9,
  },
];
