import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useAdminStore from "../../stores/useAdminStore";

function SideBar() {
  const { signOut } = useAdminStore();
  const navigate = useNavigate();
  const logOut = () => {
    signOut();
    navigate("/");
  };

  return (
    <div className="w-64 bg-green-800 text-white">
      <div className="w-full p-4 font-bold text-xl ">Admin Panel</div>
      <nav className="mt-5">
        <ul>
          <NavLink to="/">
            <li className="p-4 hover:bg-green-700">На главную</li>
          </NavLink>
          <NavLink to="admin/tours/kyrgyzstan">
            <li className="p-4 hover:bg-green-700">Кыргызстан</li>
          </NavLink>
          <NavLink to="admin/tours/centralAsia">
            <li className="p-4 hover:bg-green-700">Центральная Азия</li>
          </NavLink>
          <NavLink to="admin/staff">
            <li className="p-4 hover:bg-green-700">Персоны</li>
          </NavLink>
          <NavLink to="admin/pictures">
            <li className="p-4 hover:bg-green-700">Галерея</li>
          </NavLink>
          <NavLink to="admin/video">
            <li className="p-4 hover:bg-green-700">Видео</li>
          </NavLink>
          <li className="p-4 hover:bg-green-700" onClick={logOut}>
            Выход
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default SideBar;
